<template>
    <div class="alert-container">
        <transition name="down-in">
            <QxtAlert
                v-if="alertStore.alert.message"
                :theme="alertStore.alert.type"
                :content="alertStore.alert.message"
                :title="alertStore.alert.title"
                @close="() => alertStore.$reset()"
            />
        </transition>
    </div>
</template>

<script setup>
import { watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAlertStore } from '@/stores/alert';
import { QxtAlert } from '@asmartment/qui-base';
import { ALERT_TYPE } from '@/utils/constants/common/alert';

const router = useRouter();
const alertStore = useAlertStore();

const closeTimeout = {
    [ALERT_TYPE.SUCCESS]: 4000, // 4 seconds
    [ALERT_TYPE.ERROR]: 10000, // 10 seconds
    [ALERT_TYPE.DEFAULT]: 10000, // 10 seconds
};

const closeTime = computed(() => closeTimeout[alertStore.alert.type] || closeTimeout[ALERT_TYPE.SUCCESS]);

let timeoutId = null;

const resetAlert = () => {
    if (timeoutId) clearTimeout(timeoutId);
    alertStore.$reset();
};

const handleAlertChange = newAlert => {
    if (newAlert.message) {
        if (timeoutId) clearTimeout(timeoutId);

        timeoutId = setTimeout(resetAlert, closeTime.value);
    } else if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
    }

    if (newAlert.back) {
        router.go(-1);
    }
};

watch(() => alertStore.alert, handleAlertChange, { immediate: true });
</script>

<style scoped>
.alert-container {
    @apply fixed right-3 z-100;
    top: 7rem;
    white-space: pre-line;
}
.down-in-enter-active {
    transition: all 0.35s cubic-bezier(0.71, 0.67, 0.5, 1.35);
}

.down-in-leave-active {
    transition: all 0.25s;
}

.down-in-enter-from,
.down-in-leave-to {
    opacity: 0;
    transform: translateY(-50%);
}

.down-in-enter-to,
.down-in-leave-from {
    opacity: 1;
    transform: translateY(0);
}
</style>
