<template>
    <QxtConfirmation
        :show="show"
        :theme="themeValue"
        :title="message"
        :cancelButton="cancelButtonConfig"
        :confirmButton="confirmButtonConfig"
        @cancel="cancel"
        @confirm="confirm"
    >
        <component
            :is="customComponent"
            v-if="customComponent"
            v-bind="filteredCustomComponentConfig"
            v-on="customComponentEvents"
            @disableConfirmation="disableConfirm"
        />
        {{ note }}
    </QxtConfirmation>
</template>

<script setup>
import { QxtConfirmation } from '@asmartment/qui-base';
import EventBus from '@/utils/EventBus';
import { slugify } from '@/utils/String';
import { ref, computed, onMounted, onUnmounted } from 'vue';

const show = ref(false);
const action = ref(null);
const message = ref(null);
const type = ref(null);

const confirmBtnText = ref(null);
const cancelBtnText = ref(null);
const isConfirmDisabled = ref(false);
const showConfirmBtn = ref(true);

const customComponent = ref(null);
const customComponentConfig = ref(null);
const customComponentEvents = ref(null);
const note = ref(null);

const themeValue = computed(() => {
    switch (type.value) {
        case 'warning':
            return 'error';
        case 'success':
            return 'brand';
        default:
            return 'default';
    }
});

const confirmationId = computed(() => {
    if (!action.value?.id) {
        console.error('No unique action id for confirmation slide-up');
    }
    return action.value?.id ?? slugify(action.value?.title);
});

const cancelButtonConfig = computed(() => ({
    label: cancelBtnText.value,
    variant: 'outline',
}));

const confirmButtonConfig = computed(() => ({
    label: confirmBtnText.value,
    disabled: Boolean(isConfirmDisabled.value),
    hide: !showConfirmBtn.value,
}));

const filteredCustomComponentConfig = computed(() => {
    return Object.fromEntries(
        Object.entries(customComponentConfig.value || {}).filter(([key, value]) => typeof value === 'boolean')
    );
});

const cancel = () => {
    EventBus.emit(`cancel-${confirmationId.value}`, action.value);
    show.value = false;
    if (!action.value?.id) {
        console.error('No unique action id for confirmation slide-up');
    }
    const id = action.value?.id ?? slugify(action.value?.title);
    EventBus.emit(`cancel-confirmation-${id}`);
};

const confirm = () => {
    EventBus.emit(`confirm-${confirmationId.value}`, action.value);
    show.value = false;
};

const showModalConfirmation = (newAction) => {
    action.value = newAction;
    show.value = true;
    message.value = newAction.confirmationMessage ?? 'Are you sure you want to perform this action?';
    note.value = newAction.confirmationNote ?? null;
    confirmBtnText.value = newAction.confirmBtnText ?? 'confirm';
    cancelBtnText.value = newAction.cancelBtnText ?? 'cancel';
    type.value = newAction.confirmationType ?? 'success';
    customComponent.value = newAction.confirmationCustomComponent || null;
    customComponentConfig.value = newAction.confirmationCustomComponentConfig || null;
    customComponentEvents.value = newAction.confirmationCustomComponentEvents || null;
    isConfirmDisabled.value = newAction.isInitialConfirmDisabled || false;
    showConfirmBtn.value = newAction.showConfirmBtn ?? true;
};

const hideModalConfirmation = () => {
    if (show.value) {
        cancel();
    }
};

onMounted(() => {
    EventBus.on('show-modal-confirmation', showModalConfirmation);
    EventBus.on('hide-modal-confirmation', hideModalConfirmation);
});

onUnmounted(() => {
    EventBus.off('show-modal-confirmation', showModalConfirmation);
    EventBus.off('hide-modal-confirmation', hideModalConfirmation);
});
</script>