<template>
    <header
        id="top-bar"
        class="flex justify-between items-center mb-12"
        :class="{
            'dark bg-black py-4 px-8 -mt-4 -mx-8': $route.meta && $route.meta.loadInModal,
            'sticky py-6 z-20 top-0 bg-gray-100': $route.meta && $route.meta.stickyTopBar,
        }"
    >
        <div class="flex items-center">
            <div :class="{ 'text-white': $route.meta && $route.meta.loadInModal }">
                <router-link :to="{ name: 'home' }">
                    <icon
                        class="topbar--logo"
                        name="logo"
                    />
                </router-link>
            </div>
            <div
                v-if="Boolean(profile)"
                class="ml-8"
            >
                <customer-context-select
                    v-if="isCustomerSelector"
                    :disabled="$boolAttr(selectorsDisabled)"
                />
                <profile-context-select
                    v-else
                    :disabled="$boolAttr(selectorsDisabled)"
                />
                <community-selector :disabled="$boolAttr(selectorsDisabled)" />
            </div>
        </div>
        <div
            v-if="Boolean(profile)"
            class="flex items-start"
        >
            <div class="mr-6">
                <profile-drop-down
                    :user="profile.firstName"
                    :logout="logout"
                />
            </div>
            <Notifications />
            <LayoutSwitch :class="{ 'text-white': $route.meta && $route.meta.loadInModal }" />
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import Notifications from './Notifications';
import CustomerContextSelect from '@/components/auth/context_select/CustomerContextSelect';
import CommunitySelector from '@/components/auth/context_select/CommunitySelect';
import ProfileContextSelect from '@/components/auth/context_select/ProfileContextSelect';
import ProfileDropDown from './ProfileDropDown';
import LayoutSwitch from '@/components/layout/v3/LayoutSwitch.vue';

export default {
    components: {
        LayoutSwitch,
        CommunitySelector,
        CustomerContextSelect,
        ProfileContextSelect,
        Icon,
        Notifications,
        ProfileDropDown,
    },

    props: {
        selectorsDisabled: {
            type: Boolean,
            default: false,
        },

        logout: {
            type: Function,
            required: true,
        },
    },

    data: () => {
        return {
            isDark: false,
        };
    },

    computed: {
        ...mapGetters({
            isSuperAdmin: 'auth/isSuperAdmin',
            isQuextUser: 'auth/isQuextUser',
            profile: 'auth/profile',
        }),

        isCustomerSelector() {
            return this.isSuperAdmin || this.isQuextUser;
        },
    },
};
</script>

<style>
.topbar--logo {
    width: 5rem;
}
</style>
