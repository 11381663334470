import { computed } from 'vue';
import { useStore } from 'vuex';

export function useDigitalHumanMenuItem() {
    const store = useStore();

    const role = computed(() => store.getters['auth/userRole']);

    const authorized = computed(() => hasRole => {
        return [].concat(hasRole).includes(role.value);
    });

    const hasDHPermission = computed(() => store.getters['dh/hasPermission']);

    const digitalHumanMenuItems = computed(() => ({
        list: [
            {
                id: '__digital_human',
                label: 'Digital human',
                permissionChecker: hasDHPermission.value,
                items: [
                    {
                        icon: 'folder',
                        permissionChecker: authorized.value(['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER']),
                        label: 'Global response catalog',
                        id: 'responses.index',
                    },
                    {
                        icon: 'eye',
                        permissionChecker: authorized.value(['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER']),
                        label: 'Custom response monitoring',
                        id: 'custom_responses.index',
                    },
                    {
                        icon: 'search',
                        permissionChecker: authorized.value(['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER']),
                        label: 'Search & translate responses',
                        id: 'translate_responses.index',
                    },
                    {
                        icon: 'folder-plus',
                        permissionChecker: authorized.value(['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER']),
                        label: 'Create & manage intents',
                        id: 'manage_intents.index',
                    },
                    {
                        icon: 'settings',
                        label: 'Community settings',
                        id: 'digital_human_settings.index',
                    },
                    {
                        icon: 'price',
                        label: 'Unit availability & pricing',
                        id: 'dh.availability_pricing.index',
                    },
                    {
                        icon: 'repeat',
                        label: 'Customize your conversations',
                        id: 'conversations_responses.index',
                    },
                ],
            },
        ],
    }));

    return { digitalHumanMenuItems };
}
