import { computed } from 'vue';
import { useStore } from 'vuex';

export function useDevToolsMenuItem() {
    const profileAppCodes = computed(() => store.getters['auth/profileAppCodes']);
    const store = useStore();

    const hasProfileAppAccess = computed(() => appCode => profileAppCodes.value?.includes(appCode));

    const devToolsMenuItems = computed(() => ({
        list: [
            {
                id: '__resident_auth_dev_tools',
                label: 'Resident auth dev tools',
                permissionChecker: hasProfileAppAccess.value('RAA'),
                items: [
                    {
                        icon: 'users',
                        label: 'Profiles',
                        id: 'raaTools.profiles.index',
                    },
                    {
                        icon: 'shield',
                        label: 'Identities',
                        id: 'raaTools.identities.index',
                    },
                    {
                        icon: 'shuffle',
                        label: 'Relationship types',
                        id: 'raaTools.relationshipTypes.index',
                    },
                    {
                        icon: 'applications',
                        label: 'Applications',
                        id: 'raaTools.applications.index',
                    },
                    {
                        icon: 'key',
                        label: 'Shared data keys',
                        id: 'raaTools.sharedDataKeys.index',
                    },
                ],
            },
            {
                icon: 'cloud',
                id: '__resident_auth_local_data',
                label: 'Resident auth local data',
                permissionChecker: hasProfileAppAccess.value('RAA'),
                items: [
                    {
                        icon: 'users',
                        label: 'Persons',
                        id: 'raaTools.persons.index',
                    },
                    {
                        icon: 'view-grid',
                        label: 'Parties',
                        id: 'raaTools.parties.index',
                    },
                    {
                        icon: 'award',
                        label: 'Memberships',
                        id: 'raaTools.memberships.index',
                    },
                ],
            },
            {
                icon: 'control-method',
                id: '__permissions_dev_tools',
                label: 'Permissions dev tools',
                permissionChecker: hasProfileAppAccess.value('PRMSN'),
                items: [
                    {
                        icon: 'shield',
                        label: 'Identity permissions',
                        id: 'prmsnTools.identityPermissions.index',
                    },
                ],
            },
        ],
    }));

    return { devToolsMenuItems };
}
