import { computed } from 'vue';
import { useStore } from 'vuex';
import { useIotPermissionsStore } from '@/stores/iot/permissions';

export function useSmartCommunityMenuItem() {
    const store = useStore();
    const { hasPermissions: hasSmartCommunityPermissions } = useIotPermissionsStore();

    const hasAnySmartCommunityAccess = computed(() =>
        hasSmartCommunityPermissions([
            'TEMPORARY_USER_ACCESS_READ_LIST',
            'LOCK_READ_LIST',
            'CUSTOM_USER_GROUP_READ_LIST',
            'USER_READ_LIST',
            'RESIDENT_READ_LIST',
            'FIRE_KEY_VIEW_FIRE_KEY_TILE',
            'SMART_POLICY_READ',
            'LOCATION_READ_LIST',
            'GROUP_USER_ACCESS_READ_LIST',
            'THERMOSTAT_READ_LIST',
            'LOCK_READ_LIST',
            'DELAY_LINE_READ',
            'LEASE_READ_LIST',
            'RESIDENT_READ_LIST',
            'RESIDENT_READ_RESIDENT_EVENT_QUEUE',
            'SENSOR_INDUSTRIES_READ',
            'USER_READ_LIST',
            'TEMPORARY_USER_ACCESS_READ_LIST',
        ])
    );

    const userRole = computed(() => store.getters['auth/userRole']);

    const isAdmin = computed(() => ['SUPER_ADMIN', 'CUSTOMER_ADMIN'].includes(userRole.value));

    const smartCommunityMenuItems = computed(() => ({
        list: [
            {
                id: '__smart_community',
                label: 'Smart Community',
                permissionChecker: hasAnySmartCommunityAccess.value,
                items: [
                    {
                        icon: 'key',
                        permissionChecker: hasSmartCommunityPermissions([
                            'TEMPORARY_USER_ACCESS_READ_LIST',
                            'LOCK_READ_LIST',
                            'CUSTOM_USER_GROUP_READ_LIST',
                            'USER_READ_LIST',
                            'RESIDENT_READ_LIST',
                            'FIRE_KEY_VIEW_FIRE_KEY_TILE',
                        ]),
                        label: 'Access',
                        id: 'iot.access.index',
                    },

                    {
                        icon: 'automation',
                        permissionChecker: hasSmartCommunityPermissions([
                            'SMART_POLICY_READ_LIST',
                            'AUTO_OPEN_READ_LIST',
                            'LOCK_CREDENTIALS_SCHEDULE_READ_LIST',
                        ]),
                        label: 'Automation',
                        id: 'iot.automation',
                    },

                    {
                        icon: 'building-2',
                        permissionChecker: hasSmartCommunityPermissions(['LOCATION_READ_LIST', 'GROUP_USER_ACCESS_READ_LIST']),
                        label: 'Community',
                        id: 'iot.community',
                    },

                    {
                        icon: 'device-plug',
                        permissionChecker: hasSmartCommunityPermissions(['THERMOSTAT_READ_LIST', 'LOCK_READ_LIST']),
                        label: 'Devices',
                        id: 'iot.devices',
                    },

                    {
                        icon: 'repeat',
                        permissionChecker: hasSmartCommunityPermissions('DELAY_LINE_READ'),
                        label: 'Integration queue',
                        id: 'iot.integration_queue.index',
                    },

                    {
                        icon: 'lease-filled',
                        permissionChecker: hasSmartCommunityPermissions('LEASE_READ_LIST'),
                        label: 'Leases',
                        id: 'iot.leases.index',
                    },

                    {
                        icon: 'smart-home',
                        permissionChecker: hasSmartCommunityPermissions('RESIDENT_READ_LIST'),
                        label: 'Residents',
                        id: 'iot.residents.index',
                    },

                    {
                        icon: 'users-queue',
                        permissionChecker: hasSmartCommunityPermissions('RESIDENT_READ_RESIDENT_EVENT_QUEUE'),
                        label: 'Resident queue',
                        id: 'iot.residents-queue.index',
                    },

                    {
                        icon: 'humidity-filled',
                        permissionChecker: hasSmartCommunityPermissions('SENSOR_INDUSTRIES_READ'),
                        label: 'Sensor industries',
                        id: 'iot.si-dash.index',
                    },

                    {
                        icon: 'users',
                        permissionChecker: hasSmartCommunityPermissions('USER_READ_LIST'),
                        label: 'Staff',
                        id: 'iot.staff.index',
                    },

                    {
                        icon: 'view-grid',
                        permissionChecker: hasSmartCommunityPermissions('USER_READ_LIST'),
                        label: 'Vendors',
                        id: 'iot.vendors.index',
                    },

                    {
                        icon: 'settings',
                        permissionChecker: isAdmin.value || hasSmartCommunityPermissions('TEMPORARY_USER_ACCESS_READ_LIST'),
                        label: 'Settings',
                        id: 'iot.settings',
                    },
                ],
            },
        ],
    }));

    return { smartCommunityMenuItems };
}
