import { computed } from 'vue';
import { useStore } from 'vuex';

export function useMarketingMenuItem() {
    const store = useStore();

    const hasCMSPermission = computed(() => store.getters['cms/hasPermission']);
    const hasDHPermission = computed(() => store.getters['dh/hasPermission']);

    const hasMarketingPermission = computed(() => {
        return hasCMSPermission.value || hasDHPermission.value;
    });
    const marketingMenuItems = computed(() => ({
        list: [
            {
                id: '__marketing',
                label: 'Marketing',
                permissionChecker: hasMarketingPermission.value,
                items: [
                    {
                        icon: 'film',
                        id: 'medias.index',
                        label: 'Media gallery',
                    },
                    {
                        icon: 'credit-card',
                        id: 'guest-cards.index',
                        label: 'Guest cards',
                    },
                ],
            },
        ],
    }));

    return { marketingMenuItems };
}
