import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useAlertStore } from '@/stores/alert';
import useCustomerOptions from '@/components/layout/v3/composables/useCustomerOptions';
import useCommunityOptions from '@/components/layout/v3/composables/useCommunityOptions';
import useCommunityFilesLibrary from '@/components/layout/v3/composables/useCommunityFilesLibrary';
import EventBus from '@/utils/EventBus';
import AuthDataProvider from '@/api/auth';
import { INIT_CUSTOMERS_FETCH, SIGNED_OUT } from '@/utils/authEvents';
import { REFRESH_PERMISSIONS_EVENT } from '@/components/permission_service/constants';

const OPTIONS_REFRESH_INTERVAL = 60000;

export default function usePropertySelect() {
    const store = useStore();
    const router = useRouter();
    const { notifyError } = useAlertStore();
    const { customerId, customerContext, customerOptions, fetchCustomers } = useCustomerOptions();
    const { communityLoading, communities, fetchCommunities } = useCommunityOptions();
    useCommunityFilesLibrary();

    const customersPullingIntervalId = ref(null);
    const appCodesLoading = ref(false);

    const profile = computed(() => store.getters['auth/profile']);
    const profiles = computed(() => store.getters['auth/profiles']);
    const community = computed(() => store.getters['auth/community']);

    const companyProperties = computed(() => ({
        list: customerOptions.value,
    }));

    const selectedCompany = computed(() => customerId.value);

    const communityProperties = computed(() => ({
        list: communities.value?.map(item => ({
            ...item,
            // loading: community.value?.id === item.id ? appCodesLoading.value : false,
        })),
        loading: communityLoading.value,
    }));

    const selectedCommunity = computed(() => community.value?.id);

    watch(
        () => profile.value?.customerId,
        val => {
            if (val) {
                fetchCommunities();
            } else {
                communities.value = [];
                store.dispatch('auth/setCommunity', null);
                store.dispatch('auth/setCommunities', []);
                store.dispatch('auth/setCustomerApps', []);
            }
        },
        { immediate: true }
    );

    watch(
        () => community.value?.id,
        val => {
            if (val) {
                fetchCommunityAppCodes();
            } else {
                store.dispatch('auth/setCustomerCommunityAppCodes', []);
            }
        },
        { immediate: true }
    );

    async function handleCustomerSelect(id) {
        if (customerId.value !== id) {
            store.dispatch('auth/setCommunity', null);
            await router.replace({
                name: 'home',
            });
        }
        let newProfile = profiles.value?.[0];
        if (id) {
            const customer = customerOptions.value.find(item => item.id === id);

            newProfile = customerContext.value
                ? {
                    ...profile.value,
                    customerId: customer?.id,
                    customerName: customer?.name,
                }
                : customer;
        }

        store.dispatch('auth/setProfile', newProfile);
        EventBus.emit(REFRESH_PERMISSIONS_EVENT);
    }

    async function handleCommunitySelect(id) {
        if (community.value?.id !== id) {
            await router.replace({
                name: 'home',
            });
        }
        store.dispatch('auth/setCommunity', communities.value.find(item => item.id === id) ?? null);
        EventBus.emit(REFRESH_PERMISSIONS_EVENT);
    }

    function handlePropertyUpdate(property) {
        handleCustomerSelect(property.company);
        handleCommunitySelect(property.community);
    }

    async function fetchCommunityAppCodes() {
        appCodesLoading.value = true;
        try {
            const { content = [] } = await AuthDataProvider.adapter.get('communityLicensedAppAccess', {
                customerId: profile.value.customerId,
                communityId: community.value.id,
            });
            store.dispatch(
                'auth/setCustomerCommunityAppCodes',
                content.map(app => app.appCode)
            );
        } catch {
            notifyError('Failed to fetch applications authorized for the selected community');
        } finally {
            appCodesLoading.value = false;
        }
    }

    onMounted(async () => {
        await fetchCustomers();

        customersPullingIntervalId.value = setInterval(fetchCustomers, OPTIONS_REFRESH_INTERVAL);

        EventBus.on(INIT_CUSTOMERS_FETCH, fetchCustomers);
        EventBus.on(SIGNED_OUT, () => {
            if (customersPullingIntervalId.value) {
                clearInterval(customersPullingIntervalId.value);
            }
        });
    });

    onBeforeUnmount(() => {
        clearInterval(customersPullingIntervalId.value);
    });

    return {
        selectedCompany,
        selectedCommunity,
        companyProperties,
        communityProperties,
        handlePropertyUpdate,
        appCodesLoading,
    };
}
