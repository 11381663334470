import { ref } from 'vue';

const empty = { isOpen: false, component: undefined, onClose: () => null, title: '' };

const drawer = ref(empty);
export default initial => {
    if (initial) {
        drawer.value = { ...empty, ...initial };
    }

    return drawer;
};
