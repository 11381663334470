import { computed } from 'vue';

import { useDevToolsMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/devTools';
import { useSearchEngine } from '@/components/layout/v3/navigation/composables/menu_items/searchEngine';
import { useEmailBotItem } from '@/components/layout/v3/navigation/composables/menu_items/emailBot';
import { useSmartCommunityMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/smartCommunity';
import { useVendorsMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/vendors';
import { useAccountsReceivableMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/accountsReceivable';
import { useAccountsPayableMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/accountsPayable';
import { useGeneralLedgerMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/generalLedger';
import { usePortfolioSetupMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/portfolioSetup';
import { useQuextSetupMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/quextSetup';
import { useResidentLifecycleMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/residentLifecycle';
import { useCommunitySettingsMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/communitySettings';
import { usePhysicalPropertyLifecycleMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/physicalPropertyLifecycle';
import { useDigitalHumanAiMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/digitalHumanAi';
import { useDigitalHumanMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/digitalHuman';
import { useMarketingMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/marketing';
import { useWebsitesMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/websites';

function filterListByPermissions(menuItems) {
    return menuItems.reduce((filtered, item) => {
        if (!Object.hasOwn(item, 'permissionChecker') || item.permissionChecker === true) {
            const filteredChildren = item.children
                ? item.children.reduce((childFiltered, child) => {
                    if (!Object.hasOwn(child, 'permissionChecker') || child.permissionChecker === true) return [...childFiltered, child];
                    return childFiltered;
                }, [])
                : undefined;

            if (!filteredChildren || filteredChildren.length > 0) {
                filtered.push({
                    ...item,
                    children: filteredChildren,
                });
            }
        }

        return filtered;
    }, []);
}

export default function useNavServices() {
    const { devToolsMenuItems } = useDevToolsMenuItem();
    const { searchEngineMenuItems } = useSearchEngine();
    const { emailBotItems } = useEmailBotItem();
    const { smartCommunityMenuItems } = useSmartCommunityMenuItem();
    const { vendorsMenuItems } = useVendorsMenuItem();
    const { accountsReceivableMenuItems } = useAccountsReceivableMenuItem();
    const { accountsPayableMenuItems } = useAccountsPayableMenuItem();
    const { generalLedgerMenuItems } = useGeneralLedgerMenuItem();
    const { portfolioSetupMenuItems } = usePortfolioSetupMenuItem();
    const { quextSetupMenuItems } = useQuextSetupMenuItem();
    const { residentLifecycleMenuItems } = useResidentLifecycleMenuItem();
    const { communitySettingsMenuItems } = useCommunitySettingsMenuItem();
    const { physicalPropertyLifecycleMenuItems } = usePhysicalPropertyLifecycleMenuItem();
    const { digitalHumanAiMenuItems } = useDigitalHumanAiMenuItem();
    const { digitalHumanMenuItems } = useDigitalHumanMenuItem();
    const { marketingMenuItems } = useMarketingMenuItem();
    const { websitesMenuItems } = useWebsitesMenuItem();

    const services = computed(() => {
        const list = [
            ...websitesMenuItems.value.list,
            ...marketingMenuItems.value.list,
            ...digitalHumanMenuItems.value.list,
            ...digitalHumanAiMenuItems.value.list,
            ...physicalPropertyLifecycleMenuItems.value.list,
            ...communitySettingsMenuItems.value.list,
            ...residentLifecycleMenuItems.value.list,
            ...quextSetupMenuItems.value.list,
            ...portfolioSetupMenuItems.value.list,
            ...generalLedgerMenuItems.value.list,
            ...accountsPayableMenuItems.value.list,
            ...accountsReceivableMenuItems.value.list,
            ...vendorsMenuItems.value.list,
            ...smartCommunityMenuItems.value.list,
            ...emailBotItems.value.list,
            ...searchEngineMenuItems.value.list,
            ...devToolsMenuItems.value.list,
        ];

        const accessibleList = filterListByPermissions(list);

        if (!accessibleList.length) {
            return [];
        }

        return accessibleList;
    });

    return services;
}
