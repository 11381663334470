<template>
    <Component
        :is="drawer.component"
        :opened="drawer.isOpen"
        :title="drawer.title"
        @close="drawer.onClose"
    />
</template>

<script setup>
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useDrawer from '@/composables/useDrawer';

const drawer = useDrawer();
const router = useRouter();
const route = useRoute();

watch(
    route,
    (next, prev) => {
        if (prev) {
            drawer.value.isOpen = false;
        }

        const component = next.matched[next.matched?.length - 1]?.components.drawer;
        if (component) {
            drawer.value.isOpen = true;
            drawer.value.component = component;
            drawer.value.title = next.matched[next.matched?.length - 1]?.meta?.title;
            drawer.value.onClose = () => {
                drawer.value.isOpen = false;
                router.push({ name: next.matched[next.matched?.length - 2]?.name });
            };
        }
    },
    { immediate: true }
);
</script>
