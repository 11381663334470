import { computed } from 'vue';
import { useCoreMenuItem } from '@/components/layout/v3/navigation/composables/menu_items/core';

export default function useNavCoreServices() {
    const { coreMenuItems } = useCoreMenuItem();

    const coreServices = computed(() =>
        coreMenuItems.value.list.filter(item => {
            if (!Object.hasOwn('permissionChecker')) {
                return true;
            }

            return item.permissionChecker;
        })
    );

    return coreServices;
}
