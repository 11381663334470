import { computed } from 'vue';
import { hasAnyCommunitySettingsAccess } from '@/community_settings';

export function useCommunitySettingsMenuItem() {
    const communitySettingsMenuItems = computed(() => ({
        list: [
            {
                id: '__community_settings',
                label: 'Community settings',
                permissionChecker: hasAnyCommunitySettingsAccess(),
                items: [
                    {
                        icon: 'unit',
                        label: 'Community settings',
                        id: 'community.settings.index',
                    },
                ],
            },
        ],
    }));

    return { communitySettingsMenuItems };
}
