<template>
    <div :class="{ v3 }">
        <span class="label pr-2">V2</span>
        <QxtTooltip
            :title="`Viewing V${v3 ? 3 : 2}`"
            position-y="bottom"
            :position-x="v3 ? 'auto' : 'middle'"
            theme="primary"
            with-arrow
        >
            <QxtToggle
                :checked="v3"
                @change="changeLayoutVersion"
            />
        </QxtTooltip>
        <span class="label">V3 (Beta)</span>
    </div>
</template>

<script setup>
import { QxtToggle, QxtTooltip } from '@asmartment/qui-base';
import { storeToRefs } from 'pinia';
import { uiVersions, useUIStore } from '@/stores/ui';

const uiStore = useUIStore();
const { setUIVersion } = uiStore;
const { v3 } = storeToRefs(uiStore);

function changeLayoutVersion(value) {
    setUIVersion(value ? uiVersions.V3 : uiVersions.V2);
}
</script>

<style scoped>
.label {
    font-weight: var(--font-medium, 500);
    vertical-align: top;
}
.v3 {
    color: var(--gray-700);
}
</style>
