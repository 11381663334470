import { computed } from 'vue';
import { useStore } from 'vuex';
import { RI, AMNT, CBR, SREQ, MSGS, hasPpmAccess } from '@/utils/ppm/permissions';

export function usePhysicalPropertyLifecycleMenuItem() {
    const store = useStore();

    const hasSreqPermission = computed(() => store.getters['sreq/hasPermission']);
    const hasMSGSPermission = computed(() => store.getters['msgs/hasPermission']);
    const hasAMNTPermission = computed(() => store.getters['amnt/hasPermission']);
    const hasCBRPermission = computed(() => store.getters['cbr/hasPermission']);
    const hasRIPermission = computed(() => store.getters['ri/hasPermission']);

    const physicalPropertyLifecycleMenuItems = computed(() => ({
        list: [
            {
                id: '__physical_property_lifecycle',
                label: 'Physical property lifecycle',
                permissionChecker: hasPpmAccess(),
                items: [
                    {
                        icon: 'service-request',
                        permissionChecker: hasSreqPermission.value(SREQ.SERVICE_REQUEST_VIEW),
                        label: 'Service requests',
                        id: 'sreq.index',
                    },
                    {
                        icon: 'message-circle',
                        permissionChecker: hasMSGSPermission.value(MSGS.MESSAGES_VIEW),
                        label: 'Resident messaging',
                        id: 'msgs.index',
                    },
                    {
                        icon: 'view-tree',
                        permissionChecker: hasCBRPermission.value(CBR.LIVING_SPACES_VIEW),
                        label: 'Community structure',
                        id: 'cbr.living.communityStructure',
                    },
                    {
                        icon: 'rentable-items',
                        permissionChecker: hasRIPermission.value(RI.VIEW_RI),
                        label: 'Rentable items',
                        id: 'ri.index',
                    },
                    {
                        icon: 'sofa',
                        permissionChecker: hasAMNTPermission.value(AMNT.CL_AMENITY_VIEW),
                        label: 'Property amenities',
                        id: 'amenities.community.individual.index',
                    },
                ],
            },
        ],
    }));

    return { physicalPropertyLifecycleMenuItems };
}
