import { computed } from 'vue';
//import routes from '@/router/calendar/_calendar_events';
//import store from '@/store';

//const role = store.getters['auth/userRole'];

export function useCoreMenuItem() {
    const coreMenuItems = computed(() => ({
        list: [
            {
                icon: 'home-dash',
                label: 'Home',
                id: 'home',
            },
            // {
            //     icon: 'calendar-2',
            //     label: 'Calendar',
            //     id: 'calendar_events.index',
            //     permissionChecker: routes[0].meta?.allowedRoles.includes(role),
            // },
            {
                icon: 'file-text',
                label: 'Reporting',
                id: 'dashboard',
            },
        ],
    }));

    return { coreMenuItems };
}
