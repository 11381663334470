<template>
    <QxtSideNav
        :expanded="true"
        :property="{ company: selectedCompany, community: selectedCommunity }"
        :properties="properties"
        :core-services="coreServices"
        :services="services"
        :profile="profile"
        :addons="addons"
        :model-value="route.name"
        @update:model-value="updateActiveNavItem"
        @update:property="handlePropertyUpdate"
    />
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import { QxtSideNav } from '@asmartment/qui-base';
import { useAlertStore } from '@/stores/alert';
import { useIntegrationPartnersStore } from '@/stores/integration_partners/integrationPartners';
import IntegrationsPartnersDataProvider from '@/api/integration_partner';
import { useRouter, useRoute } from 'vue-router';
import usePropertySelect from './composables/usePropertySelect';
import useNavProfile from './composables/useNavProfile';
import useNavCoreServices from './composables/useNavCoreServices';
import useNavServices from './composables/useNavServices';

/* 
    Preliminary basic custom group. 
    I'll keep it in case somebody will ask to enable custom groups.

    const groups = [
    {
        id: 'property',
        label: 'Property',
        servicesIds: ['__iot', '__digital_human', '__digital_human_ai', '__websites', '__marketing'],
    },
]; */

const addons = [
    {
        icon: 'message-square',
        id: '1',
        label: 'Chat with Support',
    },
];

const router = useRouter();
const route = useRoute();
const store = useStore();
const ipsStore = useIntegrationPartnersStore();
const { notifyError } = useAlertStore();
const { companyProperties, communityProperties, selectedCompany, selectedCommunity, handlePropertyUpdate, appCodesLoading } =
    usePropertySelect();

const profile = useNavProfile();
const coreServices = useNavCoreServices();
const servicesList = useNavServices();

const properties = computed(() => {
    if (companyProperties.value.list.length === 1 && communityProperties.value.list.length === 1) {
        return { community: communityProperties.value };
    }

    return { company: companyProperties.value, community: communityProperties.value };
});

const services = computed(() => ({
    list: servicesList.value,
    loading: appCodesLoading.value,
}));

const clearWebsiteInformation = () => store.dispatch('website_info/clearWebsiteInformation');

const updateActiveNavItem = navItem => {
    if (!navItem.id.startsWith('__')) {
        router.push({ name: navItem.id, params: navItem.data?.routeParams });
    }
};

onMounted(() => {
    clearWebsiteInformation();

    IntegrationsPartnersDataProvider.adapter
        .get('permissions')
        .then(ipsPermissions => {
            ipsStore.setIpsV2Permissions(ipsPermissions);
        })
        .catch(error => notifyError(error));
});
</script>
