//import { h } from 'vue';

const routes = [
    {
        name: 'home',
        path: '/',
        components: {
            default: () => import('@/views/search/Index'),
            ui_v3: () => import('@/views/search/Index'),
            //drawer: () => h('div', 'Test'),
        },
        props: {
            ui_v3: { hide: true, class: 'ml-12 mr-12' },
        },
        meta: { title: 'Home' },
    },
];

export default routes;
