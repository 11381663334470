import { signOut } from 'aws-amplify/auth';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { USER_CATEGORIES } from '@/components/auth/constants';

export default function useNavProfile() {
    const actions = [
        // {
        //     title: 'View Profile',
        //     icon: 'user',
        // },
        {
            title: 'Logout',
            icon: 'log-out',
            action: signOut,
        },
    ];
    const store = useStore();

    const profile = computed(() => store.getters['auth/profile']);

    const profileProps = computed(() => ({
        actions: actions,
        title: `${profile.value.firstName} ${profile.value.lastName}`,
        role: Object.values(USER_CATEGORIES)
            .find(item => item.type === profile.value?.type)
            ?.alias?.replace(/^\w/, c => c.toUpperCase()),
        description: Object.values(USER_CATEGORIES)
            .find(item => item.type === profile.value?.type)
            ?.alias?.replace(/^\w/, c => c.toUpperCase()),
    }));

    return profileProps;
}
